<template>
  <v-img
    v-if="!loadImg"
    :contain="!cover"
    :cover="cover"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :src="src"
    :class="rounded ? 'rounded-lg' : ''"
    height="auto"
    width="100%"
    @click="previewImage"
  >
    <!-- :lazy-src="placeholderImage" -->
    <template v-slot:placeholder>
      <loader loading size="30" height="100%" color="primary"> </loader>
    </template>
  </v-img>
  <img
    v-else
    :src="src"
    class="rounded-lg html-img"
    :style="`max-height:${maxHeight}`"
    alt=""
  />
</template>

<script>
import placeholderImage from "@/mixin/placeholder-image";

import { createNamespacedHelpers } from "vuex";
const { mapMutations } = createNamespacedHelpers("popup");
export default {
  mixins: [placeholderImage],
  props: {
    src: { type: String },
    maxHeight: { type: String, default: "400px" },
    maxWidth: { type: String, default: "100%" },
    cover: { type: Boolean, default: false },
    loadImg: { type: Boolean, default: false },
    rounded: { type: Boolean, default: true },
    openPopup: { type: Boolean, default: true },
  },
  methods: {
    ...mapMutations(["SET_POPUP"]),
    previewImage() {
      if (!this.openPopup) return;
      this.SET_POPUP({
        show: true,
        component: "zoom-image",
        componentProps: {
          src: this.src,
        },
        // modalOptions:{}
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.html-img {
  max-width: 100%;
  height: auto;
}
</style>
